import $ from 'jquery';
import SmoothScroll from 'smooth-scroll';

var MAIN = MAIN || {};

/* ================================================== *
/*
/*    function
/*
/* ================================================== */

MAIN.fn = {};

/* ------------------------------------------------- *
/*        nav1
/* ------------------------------------------------- */

MAIN.fn.nav1 = {
  emt: {
    target : '.js-header1',
    btn1   : '.js-header1__btnToggle1',
    btn2   : '.js-header1__btnClose1',
    list1  : '.js-header1__lists1',
    nav1   : '.js-header1__nav1',
    spd1   : '250',
  },
  init: function () {
    var emt = this.emt;
    $(emt.btn1).on('click', function(){
      if(!$(emt.target).hasClass('-active')) {
        $(emt.target).addClass('-active');
      } else {
        $(emt.target).removeClass('-active');
      }
    });
    $(emt.btn2).on('click', function(){
      if(!$(emt.target).hasClass('-active')) {
        $(emt.target).addClass('-active');
      } else {
        $(emt.target).removeClass('-active');
      }
    });
    $(emt.list1).find('span').on('click', function(){
      if( $(this).next('ul').css('display') === 'none' ){
        $(this).addClass('-active');
        $(this).next('ul').slideDown(emt.spd1);
      } else {
        $(this).removeClass('-active');
        $(this).next('ul').slideUp(emt.spd1);
      }
    });
  },
  hide: function() {
    var emt = this.emt;
    var doch = $(document).innerHeight();
    var winh = $(window).innerHeight() + 10;
    var bottom = doch - winh;
    if (bottom <= $(window).scrollTop()) {
      $(emt.target).addClass('-hide');
    } else {
      $(emt.target).removeClass('-hide');
    }
  }
}

/* ------------------------------------------------- *
/*        pageScroll
/* ------------------------------------------------- */

MAIN.fn.pageScroll1 = {
  emt: {
    id          : '',
    speed       : 0,
    headerHight : 120,
  },
  init: function() {
    var emt = this.emt;
    emt.id  = location.hash;
    if ( '' != emt.id && emt.id != '#top' ) {
      var pos = $(emt.id).offset().top - emt.headerHight;
      $('html').animate({ scrollTop: pos }, emt.speed );
    }
  }
}

/* ------------------------------------------------- *
/*        smooth-scroll1
/* ------------------------------------------------- */

MAIN.fn.smoothScroll1 = {
  init: function() {
    var scroll = new SmoothScroll('.js-smooth',{
      speed  : 250,
      easing : 'easeOutCubic',
      header : '[data-scroll-header]'
    });
  }
}

/* ------------------------------------------------- *
/*        kv1__contact1
/* ------------------------------------------------- */

MAIN.fn.contact1 = {
  emt: {
    target: '.js-contact1',
  },
  init: function() {
    var emt = this.emt;
    $(emt.target).find('.kv1__contact1__title1').on('click', function(){
      if( $(emt.target).hasClass('-active') ){
        $(emt.target).removeClass('-active');
      } else {
        $(emt.target).addClass('-active');
      }
    });
  }
}

/* ------------------------------------------------- *
/*        scroll1
/* ------------------------------------------------- */

MAIN.fn.scroll1 = {
  emt: {
    target: '.js-header1',
    scroll: '',
  },
  init: function() {
    var emt = this.emt;
    emt.scroll = $(window).scrollTop();
    if( $('body').hasClass('-top') ){
      if( emt.scroll > 250 ){
        $(emt.target).addClass('-type1');
      } else {
        $(emt.target).removeClass('-type1');
      }
    }
  }
};

/* ------------------------------------------------- *
/*        accordion1
/* ------------------------------------------------- */

MAIN.fn.accordion1 = {
  emt: {
    target: '.js-accordion1',
    spd1: '250',
  },
  init: function() {
    var emt = this.emt;
    $(emt.target).on('click', function(){
      if( $(this).next('div').css('display') === 'none' ){
        $(this).next('div').slideDown(emt.spd1);
        $(this).addClass('-active');
      } else {
        $(this).next('div').slideUp(emt.spd1);
        $(this).removeClass('-active');
      }
    });
  }
};

/* ================================================== *
/*
/*    run
/*
/* ================================================== */

$(document).ready(function () {
  MAIN.fn.nav1.init();
  MAIN.fn.smoothScroll1.init();
  MAIN.fn.contact1.init();
  MAIN.fn.accordion1.init();
});

$(window).scroll(function(){
  MAIN.fn.nav1.hide();
  MAIN.fn.scroll1.init();
});

$(window).on('load', function(){
  MAIN.fn.pageScroll1.init();
});
